export const ELayouts = {
    DEFAULT: "default",
    DIAMOND: "diamond",
    SECONDARY_GROOVE: "sec_groove",
    PRIMARY_GROOVE: "pri_groove"
}

export const ELocations = {
    GENERAL: "general",
    DUBAI: "dubai",
    PRISTINA: "pristina",
    GELSENKIRCHEN: "gelsenkirchen",
    CLEARWATER: "clearwater",
    MARIBOR: "maribor"
}