import { useState, useEffect } from "react";
import fetchConfiguration from "api/fetchConfiguration";

const useConfig = () => {
  const [config, setConfig] = useState({
    viewMode: 1,
    enabled: 1,
    layout: "pri_groove",
  });

  const getConfiguration = async () => {
    const response = await fetchConfiguration();
    setConfig(response);
  };

  useEffect(() => {
    getConfiguration();
    const t = setInterval(() => {
      getConfiguration();
    }, 10000);
    return () => {
      clearInterval(t);
    };
  }, []);

  return config;
};
export default useConfig;
