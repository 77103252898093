export const loadFallbackImage = () => {
  let img;
  try {
    img = require(`assets/images/default.svg`).default;
  } catch (error) {
    console.log(error);
    return img;
  }
  return img;
};
