import "./index.css";
import logoImg from "assets/images/GO LOGO.svg";
import slideImg from "assets/images/SLIDE.svg";
import leaderboardImg from "assets/images/LEADERBOARD.svg";
import { testIds } from "enums/testIds";

/**
 * @name Header
 * @returns the header with the logo/leaderboard
 */
function Header() {
  return (
    <div className="header" data-testid={testIds.leaderboard_header}>
      <img src={logoImg} className="logo" alt="logo" />
      <img src={slideImg} className="slash" alt="slash" />
      <img src={leaderboardImg} className="leaderboard" alt="leaderboard" />
    </div>
  );
}

export default Header;
