import config from "initConfig";
import "./index.css";
import { testIds } from "enums/testIds";
import { useEffect, useState } from "react";
/**
 * @name NavPanel
 * @returns the button with the locations
 */

const NavPanel = ({ setView }) => {
  const { getOnlineConfig } = config;
  const [locations, setLocs] = useState([]);
  
  useEffect(() => {
    getOnlineConfig().then(data => setLocs(data.map( store => store.location)))
  }, [])
  
  return (
    <div className="NavPanel" data-testid={testIds.NavPanel}>
      <div className="btn-group dropright">
        <button
          type="button"
          className="btn btn-gameover dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="sr-only">View Boards</span>
        </button>
        <div className="dropdown-menu">
          {locations &&
            locations.map((loc, ind) => (
              <button
                className="dropdown-item"
                data-testid={`test_${loc}`}
                key={ind}
                onClick={() => setView((prev) => ({ ...prev, location: loc }))}
              >
                {loc.toUpperCase()}
              </button>
            ))}
          <button
            className="dropdown-item"
            onClick={() => setView((prev) => ({ ...prev, location: null }))}
          >
            HOME
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavPanel;
