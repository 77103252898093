/**
 * @name GeneralBoard.Helper
 * @param {*} fetchHighScores
 * @param {*}  setTeams 
 * @exports fetchScores helper function to the GeneralBoard.helpers.jsx component
 */

export  const fetchScores = async (fetchHighScores, setTeams) => {
    let res = await fetchHighScores()

    if (!res.length > 1) return;

    res.map(item => ({ ...item, location: "default" }))
    setTeams(res)
}

export const getTeamsData = async (getTeams,setTeams,location) => {
    const data = await getTeams({location});
    setTeams(data);
};
