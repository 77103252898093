import "./index.css";
import config from "../../../package.json";
import appConfig from "initConfig";

/**
 * @name AppVersion
 * @returns the app version
 */
function AppVersion() {
  const { locationConstructor } = appConfig;
  return (
    <>
      <div className="app-version-wraper">
        <p>v{config.version}</p>
      </div>
      <div className="app-location-wraper">
        <p>{locationConstructor().toUpperCase()}</p>
      </div>
    </>
  );
}

export default AppVersion;
