import { useState } from "react";
import Slider from "react-slick";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CarouselSettings } from "../../enums/CarouselSettings";
import { testIds } from "enums/testIds";

/**
 * @name Carousel
 * @param {handleOffSet} props offSet -> Increments and re initialises the index sent to backend on every slide
 * @returns A leader board instance with 3 - 4 rooms only
 */
const Carousel = (props) => {
  let l = 10;
  const initialOffSet = 1;
  const [offSet, setOffSet] = useState(initialOffSet);
  const { timer, testId } = props;
  const settings = { ...CarouselSettings, autoplaySpeed: timer };

  const handleOffSet = () => {
    if (offSet === l) return setOffSet(initialOffSet);
    setOffSet(offSet + 1);
  };
  return (
    <div className="carousel" data-testid={testId ? testId : testIds.carousel}>
      <Slider {...settings} beforeChange={handleOffSet}>
        {props.children}
      </Slider>
    </div>
  );
};
export default Carousel;
