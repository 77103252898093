import config from "../initConfig";

function fetchHighScores() {
  const { urlConstructor } = config;

  const URL = urlConstructor();

  return fetch(`${URL}/fetchHighScores.php`)
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => {
      console.log(error);
      return [];
    });
}
export default fetchHighScores;
