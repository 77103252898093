import { useState } from "react";
import getTeams from "api/getTeams.js";
import fetchHighScores from "api/fetchHighScores.js";
import { ELocations } from "enums/appConfig.js";
import "./index.css";
import { useEffectHookOnGeneralBoard } from "hooks/useEffectHookOnGeneralBoard.js";
import Team from "../team/index.jsx";
import { testIds } from "enums/testIds";

/**
 * @name GeneralBoard
 * @param {*} props
 * @param {*} location
 * @returns the general board based on the location
 */
const GeneralBoard = (props) => {
  const [teams, setTeams] = useState([]);
  const getTeamsData = async (location) => {
    const data = await getTeams({ location });
    setTeams(data);
  };

  useEffectHookOnGeneralBoard(
    props,
    ELocations,
    fetchHighScores,
    setTeams,
    getTeamsData
  );

  return (
    <div className="general-board">
      <table className="teams-table">
        <thead>
          <tr>
            <th aria-label={testIds.room_header}>ROOM</th>
            <th className="team-name-h" aria-label={testIds.team_name_header}>
              TEAM NAME
            </th>
            <th aria-label={testIds.escape_time_header}>ESCAPE TIME</th>
          </tr>
        </thead>
        <tbody>
          {teams &&
            teams.map((team) => (
              <Team
                views={{ ...props.views, isDefaultView: true }}
                data={team}
                key={team.room_name}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeneralBoard;
