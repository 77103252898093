import { useState } from "react";
import ServerError from "components/serverError";
import BackgroundVideo from "components/backgroundVideo";
import Header from "components/header";
import NavPanel from "components/navPanel";
import GeneralBoard from "components/generalBoard";
import Teams from "components/teams";
import AppVersion from "components/appVersion";
import config from "./initConfig";
import Diamond from "./Views/Diamond/Diamond";
import Groove from "./Views/Groove/Groove";
import useConfig from "./hooks/useConfig";
import { ELayouts } from "./enums/appConfig";
import { testIds } from "enums/testIds";

function App() {
  const { locationConstructor } = config;
  const initialState = {
    location: locationConstructor(),
    isAuth: false,
  };
  //ToDo USe Reducers over useState
  const [state, setState] = useState(initialState);
  const configuration = useConfig();

  const views = {
    isDiamondView:
      !config.isGeneralLeaderboard() &&
      configuration.layoutMode === ELayouts.DIAMOND,
    isDefaultView:
      !config.isGeneralLeaderboard() &&
      configuration.layoutMode === ELayouts.DEFAULT,
    isGrooveView:
      (!config.isGeneralLeaderboard() &&
        configuration.layoutMode === ELayouts.PRIMARY_GROOVE) ||
      configuration.layoutMode === ELayouts.SECONDARY_GROOVE,
    isSecondaryGrooveView:
      configuration.layoutMode === ELayouts.SECONDARY_GROOVE,
  };

  return (
    <div className="app">
      <ServerError isEnabled={configuration.enabled} />
      <BackgroundVideo />

      <Header />
      {config.isGeneralLeaderboard() && <NavPanel setView={setState} />}
      {/* {config.isWeb() && <HighScores/> } */}
      {config.isGeneralLeaderboard() && (
        <GeneralBoard
          location={state.location}
          views={views}
          testIds={testIds}
        />
      )}
      {/* Layouts */}
      {views.isDefaultView && (
        <Teams views={views} configuration={configuration} />
      )}
      {views.isDiamondView && (
        <Diamond views={views} configuration={configuration} />
      )}
      {views.isGrooveView && (
        <Groove views={views} configuration={configuration} />
      )}
      <AppVersion />
    </div>
  );
}

export default App;
