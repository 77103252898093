import { useState } from "react";
import "./index.css";
import Offline from "../offline/index";
import { pingServer } from "helpers/ServerError.helper";
import { useEffectHookOnServerError } from "hooks/useEffectHookOnServerError";
import { testIds } from "enums/testIds";

/**
 * @name ServerError
 * @returns a message to the user when there is an error with the server
 */

function ServerError({ isEnabled }) {
  const [gotError, setGotError] = useState(false);

  useEffectHookOnServerError(pingServer, setGotError);

  return gotError ? (
    <>
      <Offline />
      <div data-testid={testIds.server_error} className="server-error">
        Server Error
      </div>
    </>
  ) : isEnabled === "0" ? (
    <>
      <div className="server-error">
        Please Contact your Administrator to enable LeaderBoard
      </div>
      <Offline />
    </>
  ) : null;
}
export default ServerError;
