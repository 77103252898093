import { useEffect, useState } from "react";
import calculateTime from "utils/calculateTime";
import { getRoomLogo, getDefaultRoomLogo } from "utils/getRoomLogo";
import { loadFallbackImage } from "utils/loadFallbackImages";
import "./index.css";
import { testIds } from "enums/testIds";

function Team({ data, index, views }) {
  const [time, setTime] = useState("");
  const [roomLogo, setRoomLogo] = useState(null);
  const [fallback] = useState(loadFallbackImage().default);

  const initialIndex = 1;
  useEffect(() => {
    const timeString = calculateTime(data.score);
    setTime(timeString);

    /**Data . Location is only for General leader board */
    let img = getRoomLogo(data.room_name.toUpperCase(), data.location) || getDefaultRoomLogo(data.room_name.toUpperCase()) || loadFallbackImage();
    img && setRoomLogo(img);
  }, [data]);

  return (
    <tr className={!views.isDefaultView ? "diamond" : ""}>
      {views.isDefaultView ? (
        <td className="room-logo">
          <img src={roomLogo || fallback} alt={`${data.room_name} logo`} />
        </td>
      ) : (
        <td className="team-index">
          <div
            data-testid={testIds.team_index}
            className={views.isSecondaryGrooveView ? "groove2 border-top" : ""}
          ></div>
          {index + initialIndex}
        </td>
      )}
      <td className="team-name">
        <div>{data.team_name.toUpperCase()}</div>
      </td>
      <td data-testid={testIds.escape_time} className="escape-time">
        {time}
      </td>
    </tr>
  );
}
export default Team;
