/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Team from "components/team";
import Carousel from "components/carousel";
import getTeams from "api/getTeams";
import { getRoomLogo, getDefaultRoomLogo } from "utils/getRoomLogo";
import { loadFallbackImage } from "utils/loadFallbackImages";
import { reArrangeRooms, getTeamsData } from "utils/diamond.helpers";
import "./groove.css";
import { testIds } from "enums/testIds";

const Groove = ({ views: layouts, configuration }) => {
  const [views, setViews] = useState([]);
  const [timer, setTimer] = useState([]);
  const [fallback] = useState(loadFallbackImage());

  const getTeamsDataHelper = () =>
    getTeamsData(configuration, getTeams, reArrangeRooms, setViews, setTimer);

  useEffect(() => {
    getTeamsDataHelper();
    const t = setInterval(() => {
      getTeamsDataHelper();
    }, 2000);
    return () => {
      clearInterval(t);
    };
  }, []);

  return (
    <Carousel timer={timer} testId={testIds.groove_carousel}>
      {views.map((view, vIndex) => (
        <table
          className={`${layouts.isGrooveView ? "groove-table" : ""
            } teams-table`}
          key={vIndex}
        >
          <thead>
            <tr>
              <th>POSITION</th>
              <th className="team-name-h">TEAM NAME</th>
              <th>ESCAPE TIME</th>
            </tr>
          </thead>
          {view.map((room, rIndex) => {
            let firstRoom =view[rIndex][0]
            let img = getRoomLogo(firstRoom.room_name) || getDefaultRoomLogo(firstRoom.room_name) || fallback
            return (
              <tbody
                id="groove"
                className={layouts.isSecondaryGrooveView ? "groove2" : ""}
                key={rIndex}
              >
                <tr>
                  <td colSpan={12}>
                    <img
                      src={img}
                      alt={`${firstRoom.room_name} logo`}
                      className="room__logo"
                    />
                  </td>
                </tr>
                {room.map((team, tIndex) => (
                  <Team
                    index={tIndex}
                    data={team}
                    key={`${rIndex}${tIndex}`}
                    views={layouts}
                  />
                ))}
              </tbody>
            )
          })}
        </table>
      ))}
    </Carousel>
  );
};
export default Groove;
