/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import getTeams from "api/getTeams";
import "./index.css";
import Team from "../team";
import { testIds } from "enums/testIds";

/**
 * @name Teams
 * @param {viewMode} -> Determines how many teams per room to retrieve
 * @returns A Table with all teams results
 */
function Teams({ views, configuration }) {
  const [teams, setTeams] = useState([]);

  const getTeamsData = async () => {
    const { viewMode } = configuration;
    const data = await getTeams({ viewMode });
    setTeams(data);
  };

  useEffect(() => {
    getTeamsData();
    const t = setInterval(() => {
      getTeamsData();
    }, 2000);
    return () => {
      clearInterval(t);
    };
  }, []);

  return (
    <table className="teams-table" data-testid={testIds.teams_component}>
      <thead>
        <tr>
          <th>ROOM</th>
          <th className="team-name-h">TEAM NAME</th>
          <th>ESCAPE TIME</th>
        </tr>
      </thead>
      <tbody>
        {teams &&
          teams.map((team, index) => (
            <Team data={team} key={index} views={views} />
          ))}
      </tbody>
    </table>
  );
}
export default Teams;
