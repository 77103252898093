export const testIds = {
  backgroundVideo: "bg-video",
  carousel: "carousel",
  mock_slider: "mock-slider",
  diamond_carousel: "diamond-carousel",
  room_header: "room-header",
  team_name_header: "team-name-header",
  escape_time_header: "escape-time-header",
  groove_carousel: "groove-carousel",
  leaderboard_header: "leaderboard-header",
  highScores: "highScores",
  NavPanel: "NavPanel",
  offline_svg: "offline-svg",
  server_error: "server-error",
  escape_time: "escape-time",
  team_index: "team-index",
  teams_component: "teams-component",
};
