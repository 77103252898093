function calculateTime(remainingTime) {
  let hours = Math.floor(remainingTime / 3600000);
  let minutes = Math.floor((remainingTime - hours * 60000) / 60000);
  let seconds = Math.floor(
    (remainingTime - hours * 60000 - minutes * 60000) / 1000
  );
  hours = hours > 9 ? hours : minutes < 60 ? "00" : `0${hours}`;
  minutes =
    minutes > 9 ? (minutes > 60 ? `0${minutes - 60}` : minutes) : `0${minutes}`;
  seconds = seconds > 9 ? seconds : `0${seconds}`;
  return hours + ":" + minutes + ":" + seconds;
}
export default calculateTime;
