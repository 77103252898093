import "./index.css";
import bgVideo from "assets/videos/bg_video.mp4";
import { testIds } from "enums/testIds";

/**
 * @name BackgroundVideo
 * @returns the background video
 */
function BackgroundVideo() {
  return (
    <div className="background-video-wraper">
      <video
        data-testid={testIds.backgroundVideo}
        src={bgVideo}
        autoPlay
        loop
        muted={true}
      ></video>
    </div>
  );
}

export default BackgroundVideo;
