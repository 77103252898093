/**
 * @name ServerError.helper
 * @param {*} pingApiServer 
 * @param {*} setGotError 
 * @exports pingServer helper function into the ServerError.jsx component
 */

export const pingServer = async (pingApiServer, setGotError) => {
    const gotResponse = await pingApiServer();
    setGotError(!gotResponse);
}

