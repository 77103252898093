export const reArrangeRooms = (data, PERPAGE) => {
  const ROOMS = [...new Set(data.map(({ room_name }) => room_name))];

  const reArrangedRooms = () =>
    ROOMS.map((element) => {
      return data.filter((entry) => entry.room_name === element);
    });

  const pages = [];
  for (let i = 0; i < reArrangedRooms().length; i += PERPAGE) {
    pages.push(reArrangedRooms().splice(i, PERPAGE));
  }
  return pages;
};

/**
 * @name getTeamsData
 * @description ***
 * @props ****
 * @params {getConfiguration} -> Gets the online configuration
 * @returns -> It maps teams from online to he component state
 */
export const getTeamsData = async (
  configuration,
  getTeams,
  reArrangeRooms,
  setViews,
  setTimer
) => {
  const { viewMode, timer } = configuration;
  const PERPAGE = 4 || +viewMode;
  const data = await getTeams({ viewMode });
  const pages = reArrangeRooms(data, PERPAGE);
  setViews(pages);
  setTimer(+timer);
};
