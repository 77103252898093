import config from "../initConfig";

function getTeams({ location, viewMode }) {
  const { urlConstructor, locationConstructor } = config;

  const URL = urlConstructor();
  const LOCATION = location ? location : locationConstructor();
  const view = viewMode ? viewMode : 1;

  return fetch(`${URL}/fetchTeams.php?location=${LOCATION}&view_mode=${view}`)
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => {
      console.log(error);
      return [];
    });
}
export default getTeams;
