import "./index.css";
import { testIds } from "enums/testIds";

/**
 * @name Offline
 * @returns an svg when the user is offline
 */
function Offline(props) {
  return (
    <svg
      width={512}
      height={512}
      data-name="Group 1025"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="offline"
      data-testid={testIds.offline_svg}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            width={410.34}
            height={349.75}
            fill="none"
            data-name="Rectangle 3"
          />
        </clipPath>
      </defs>
      <g transform="translate(50.832 81.126)" data-name="Group 2">
        <g clipPath="url(#clip-path)" data-name="Group 1">
          <g fill="#fff">
            <path
              transform="translate(-42.407 -113.43)"
              d="m177.89 427.6q-3.681 3.679-10.433 3.681h-50.731q-13.913 0-13.911-14.319v-127.65q0-14.313 13.911-14.316h50.731q6.752 0 10.433 3.682t3.681 10.634v127.65q0 6.958-3.681 10.638m-19.638-132.76h-32.116v116.6h32.116z"
              data-name="Path 1"
            />
            <path
              transform="translate(-132.11 -.233)"
              d="M392.308,156.85V79.729c0-1.5.032-3.2.106-5.113a46.2,46.2,0,0,1,.51-5.524c.269-2.042.545-4.159.815-6.34l-20.249,79.776H361.624L341.372,62.752q.2,3.271.616,6.34.406,2.66.612,5.524t.2,5.113V156.85H320.3V.564H342.4l24.547,86.323a5.839,5.839,0,0,1,.205,1.741,13.937,13.937,0,0,0,.2,2.35,23.874,23.874,0,0,0,.207,3.074,21.9,21.9,0,0,1,.2-3.074,17.24,17.24,0,0,1,.411-4.09L392.716.564H414.81V156.85Z"
              data-name="Path 2"
            />
            <path
              transform="translate(-211.53 -.232)"
              d="M512.834,156.849V.564h64.232V21.227H536.155V66.844h35.184V87.095H536.155v49.092h41.523v20.662Z"
              data-name="Path 3"
            />
            <path
              transform="translate(-174.65 -135.66)"
              d="M423.433,453.274h64.846V432.611H446.757V383.52h35.181V363.268H446.757V328.9H423.433Z"
              data-name="Path 4"
            />
            <path
              transform="translate(-252.5 -135.66)"
              d="M636.507,382.5h7.976q13.912-.406,13.915-14.318V328.9H635.076v37.515H612.168v14.138l25.771,72.726h24.343v-.821Z"
              data-name="Path 5"
            />
            <rect
              transform="translate(329.8 193.24)"
              width={23.32}
              height={124.38}
              data-name="Rectangle 2"
            />
            <path
              transform="translate(-25.562 -51.286)"
              d="m102.89 124.34h-40.914v19.839h17.39v31.921h23.524z"
              data-name="Path 6"
            />
            <path
              transform="translate(0 -.304)"
              d="M23.32,20.581H53.8V48.2H77.324V14.911Q77.324.743,63,.738H14.114Q0,.738,0,15.06V125.12H23.32Z"
              data-name="Path 7"
            />
          </g>
          <path
            transform="translate(0)"
            d="M405.9,164.6H212.391L194.8,271.789l-.615,13.295-.612-13.295L144.08,0H120.759L95.006,132.793H0v20.564l114.447.1L132.04,46.265l.616-13.295.612,13.295L183.04,317.586h23.321l25.476-132.328H405.9v-6.344c0-9.54.148-7.068,0-14.315"
            fill="#da1a35"
            data-name="Path 8"
          />
          <g fill="#fff">
            <path
              transform="translate(-174.65 -230.59)"
              d="M423.461,559.048h9.887c.022,0,.03.008.03.03v.945a.026.026,0,0,1-.03.03h-8.911V568h3.941c.016,0,.028.005.028.026v.947c0,.014-.012.017-.028.017h-3.941v8.938h8.911a.027.027,0,0,1,.03.03v.949c0,.019-.008.026-.03.026h-9.887c-.018,0-.029-.007-.029-.026V559.078c0-.022.011-.03.029-.03"
              data-name="Path 9"
            />
            <path
              transform="translate(-184.44 -230.6)"
              d="M450.344,559.071h3.27a3.264,3.264,0,0,1,2.526.99,3.218,3.218,0,0,1,.682,1.019,3.426,3.426,0,0,1,.24,1.215c.017,0,.025.009.025.031v1.2c0,.016-.008.023-.025.023h-.982c-.016,0-.023-.007-.023-.023v-1.151a2.215,2.215,0,0,0-1.315-2.122,2.331,2.331,0,0,0-1-.194h-3.425a2.208,2.208,0,0,0-2.147,2.152V566.4a2.162,2.162,0,0,0,.763,1.581,2.112,2.112,0,0,0,.98.489l.349.052c.138-.007.236-.01.292-.01h3.02a3.408,3.408,0,0,1,2.081.571,2.965,2.965,0,0,1,.933,1.006,2.916,2.916,0,0,1,.335.729,3.6,3.6,0,0,1,.143.921c.017,0,.025.006.025.023v3.986a3.229,3.229,0,0,1-3.184,3.2h-3.56a3.19,3.19,0,0,1-2.587-1.34,3.249,3.249,0,0,1-.6-1.857V574.5a.025.025,0,0,1,.028-.028h.952a.027.027,0,0,1,.03.028v1.3a2.209,2.209,0,0,0,2.147,2.154h3.621a2.205,2.205,0,0,0,2.147-2.154v-.066l-.023-.045v-3.868a2.23,2.23,0,0,0-1.149-2.04,2.37,2.37,0,0,0-1.186-.28H450.51a1.256,1.256,0,0,0-.2.016,3.352,3.352,0,0,1-2.024-.755,3.25,3.25,0,0,1-1.132-2.433v-4.064a3.217,3.217,0,0,1,3.187-3.2"
              data-name="Path 10"
            />
            <path
              transform="translate(-194.18 -230.6)"
              d="M473.953,559.071h3.266a3.269,3.269,0,0,1,2.531.99,3.19,3.19,0,0,1,.682,1.019,3.406,3.406,0,0,1,.24,1.215c.018,0,.025.009.025.031v2.694c0,.019-.007.028-.025.028h-.98c-.017,0-.026-.01-.026-.028v-2.643a2.22,2.22,0,0,0-1.313-2.122,2.349,2.349,0,0,0-1.006-.194h-3.424a2.22,2.22,0,0,0-2.151,2.152V575.8a2.162,2.162,0,0,0,.9,1.72,2.135,2.135,0,0,0,1.253.434h3.622a2.153,2.153,0,0,0,1.714-.9,2.131,2.131,0,0,0,.433-1.258v-2.791c.011-.019.018-.028.031-.028h.949c.018.009.025.016.025.028v2.738a3.173,3.173,0,0,1-1.41,2.637,3.138,3.138,0,0,1-1.772.56h-3.562a3.192,3.192,0,0,1-2.586-1.34,3.249,3.249,0,0,1-.6-1.857V562.267a3.218,3.218,0,0,1,3.186-3.2"
              data-name="Path 11"
            />
            <path
              transform="translate(-203.96 -230.25)"
              d="M503.127,573.17l1.312,5.419-.027.016h-.98a6.327,6.327,0,0,1-.28-1.105q-1.925-7.806-2.262-9.206-.115-.431-1.423-5.821l-3.44,14.091c-.1.372-.268,1.051-.5,2.025l-.028.016h-.976l-.028-.016q2.876-11.786,4.067-16.692.836-3.408.908-3.655Z"
              data-name="Path 12"
            />
            <path
              transform="translate(-213.75 -230.81)"
              d="M518.248,559.593h6.62a3.171,3.171,0,0,1,2.587,1.4,3.33,3.33,0,0,1,.539,1.788v3.952a3.151,3.151,0,0,1-1.325,2.527,3.308,3.308,0,0,1-1.846.614l-.163-.013H519.21v9.278a.022.022,0,0,1-.026.026h-.936c-.019,0-.029-.008-.029-.026V559.622a.026.026,0,0,1,.029-.029m.962.994V568.9h5.324a2.66,2.66,0,0,0,.28-.016l.026.016a2.242,2.242,0,0,0,1.5-.616,2.156,2.156,0,0,0,.668-1.523V562.71a2.156,2.156,0,0,0-.849-1.678,2.094,2.094,0,0,0-1.247-.445Z"
              data-name="Path 13"
            />
            <path
              transform="translate(-223.53 -230.59)"
              d="M541.976,559.048h9.889c.019,0,.027.008.027.03v.945c0,.019-.008.03-.027.03h-8.916V568h3.942c.018,0,.028.005.028.026v.947c0,.014-.009.017-.028.017h-3.942v8.938h8.916c.019,0,.027.013.027.03v.949c0,.019-.008.026-.027.026h-9.889c-.022,0-.032-.007-.032-.026V559.078c0-.022.009-.03.032-.03"
              data-name="Path 14"
            />
            <path
              transform="translate(-239.18 -230.61)"
              d="M579.918,559.1h6.717a3.209,3.209,0,0,1,2.629,1.424,3.368,3.368,0,0,1,.557,1.814v4.005a3.2,3.2,0,0,1-1.344,2.573,3.445,3.445,0,0,1-1.884.616l-.169-.016h-2.7l6.075,9.419v.024h-1.158c-.045-.034-.564-.845-1.552-2.417q-.25-.346-4.511-7.026H580.9v9.419c0,.017-.009.024-.028.024h-.95c-.019,0-.029-.007-.029-.024V559.122c0-.02.01-.026.029-.026m.977,1v8.434h5.392c.074,0,.172,0,.291-.012l.029.012a2.213,2.213,0,0,0,1.507-.627,2.132,2.132,0,0,0,.7-1.536v-4.123a2.159,2.159,0,0,0-.865-1.69,2.191,2.191,0,0,0-1.258-.458Z"
              data-name="Path 15"
            />
            <path
              transform="translate(-248.92 -230.61)"
              d="M606.71,559.1h3.505a3.23,3.23,0,0,1,2.754,1.538,3.307,3.307,0,0,1,.46,1.644v13.5a3.22,3.22,0,0,1-3.185,3.18h-3.563A3.176,3.176,0,0,1,603.5,575.8v-13.52a3.217,3.217,0,0,1,1.384-2.612,3.3,3.3,0,0,1,1.828-.57m-2.232,3.153v.072l-.03.04v13.216a2.3,2.3,0,0,0,1.394,2.247,2.163,2.163,0,0,0,.84.168h3.295a2.345,2.345,0,0,0,1.776-.625,2.318,2.318,0,0,0,.717-1.8V562.488a2.29,2.29,0,0,0-1.474-2.288,3.142,3.142,0,0,0-1.086-.141h-2.934a2.409,2.409,0,0,0-1.621.476,2.164,2.164,0,0,0-.877,1.714"
              data-name="Path 16"
            />
            <path
              transform="translate(-258.74 -230.61)"
              d="M630.527,559.1h3.506a3.229,3.229,0,0,1,2.754,1.538,3.34,3.34,0,0,1,.458,1.644v13.5a3.194,3.194,0,0,1-1.271,2.524,3.151,3.151,0,0,1-1.91.656H630.5a3.223,3.223,0,0,1-2.658-1.424,3.262,3.262,0,0,1-.532-1.734v-13.52a3.22,3.22,0,0,1,1.385-2.612,3.306,3.306,0,0,1,1.827-.57m-2.23,3.153v.072l-.029.04v13.216a2.294,2.294,0,0,0,1.4,2.247,2.144,2.144,0,0,0,.84.168H633.8a2.347,2.347,0,0,0,1.774-.625,2.3,2.3,0,0,0,.714-1.8V562.488a2.288,2.288,0,0,0-1.468-2.288,3.165,3.165,0,0,0-1.086-.141h-2.938a2.389,2.389,0,0,0-1.617.476,2.147,2.147,0,0,0-.879,1.714"
              data-name="Path 17"
            />
            <path
              transform="translate(-268.57 -229.53)"
              d="M651.141,556.48h.027q0,.009,4.055,12.165,2.564,7.726,2.566,7.751h.029q.177-.559,4.359-12.405,2.412-6.854,2.64-7.511h.025v21.4c0,.018-.009.031-.025.031H663.9c-.012,0-.018-.013-.018-.031V562.106h-.012q-.124.4-4.54,12.88c-1.006,2.841-1.507,4.268-1.507,4.29h-.029l-4.244-12.742q-.559-1.644-1.466-4.415h-.017v15.758a.028.028,0,0,1-.027.031h-.867c-.017,0-.027-.013-.027-.031Z"
              data-name="Path 18"
            />
            <path
              transform="translate(-281.1 -230.6)"
              d="M684.682,559.071h3.273a3.25,3.25,0,0,1,2.525.99,3.194,3.194,0,0,1,.685,1.019,3.47,3.47,0,0,1,.238,1.215c.018,0,.028.009.028.031v1.2c0,.016-.009.023-.028.023h-.976c-.019,0-.029-.007-.029-.023v-1.151a2.216,2.216,0,0,0-1.312-2.122,2.359,2.359,0,0,0-1.006-.194h-3.422a2.215,2.215,0,0,0-2.151,2.152V566.4a2.149,2.149,0,0,0,.769,1.581,2.089,2.089,0,0,0,.975.489l.349.052c.143-.007.237-.01.3-.01h3.015a3.4,3.4,0,0,1,2.081.571,2.951,2.951,0,0,1,.938,1.006,3.112,3.112,0,0,1,.335.729,3.776,3.776,0,0,1,.139.921c.018,0,.028.006.028.023v3.986a3.227,3.227,0,0,1-3.185,3.2h-3.563a3.186,3.186,0,0,1-2.58-1.34,3.246,3.246,0,0,1-.6-1.857V574.5c0-.017.006-.028.024-.028h.95c.02,0,.031.01.031.028v1.3a2.206,2.206,0,0,0,2.151,2.154h3.616a2.22,2.22,0,0,0,2.153-2.154v-.066l-.029-.045v-3.868a2.226,2.226,0,0,0-1.148-2.04,2.376,2.376,0,0,0-1.184-.28h-3.215a1.2,1.2,0,0,0-.194.016,3.365,3.365,0,0,1-2.028-.755,3.247,3.247,0,0,1-1.128-2.433v-4.064a3.214,3.214,0,0,1,3.18-3.2"
              data-name="Path 19"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Offline;
