import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
if (window && window.api) {
  // ELECTRON / PROD mode
  // call the Electron API to fetch the exposed ENV variable
  window.api
    .invoke(
      "env-variables",
      "URL_AHS_SYSTEM",
      "URL_LEADERBOARD_SYSTEM",
      "LOCATION"
    )
    .then((data) => {
      console.log("Fetched ENV variables", data);

      // set the ENV variables as global variables
      window.ENV = data;
    })
    .then(() => {
      // render the DOM
      renderDOM();
    });
} else {
  // no OS variables in this case
  window.ENV = {
    REACT_APP_URL_AHS_SYSTEM: "http://localhost/ahs_system",
  };
  // render the DOM
  renderDOM();
}

function renderDOM() {
  // render the React DOM
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
