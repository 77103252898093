/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {fetchScores} from "helpers/GeneralBoard.helper";
import { useCallback } from "react";

/**
 * @name useEffectHookOnGeneralBoard
 * @param {*} props 
 * @param {*} fetchHighScores 
 * @param {*} setTeams 
 * @param {*} getTeamsData 
 * @exports useEffectHookOnGeneralBoard helper function into the GeneralBoard.jsx component
 */

 export function useEffectHookOnGeneralBoard(props,ELocations ,fetchHighScores, setTeams, getTeamsData) {
    const memoizedGetTeamsData = useCallback(getTeamsData,[]);
  
    useEffect(() => {
      if (props.location === ELocations.GENERAL) {
        fetchScores(fetchHighScores, setTeams)
        const t = setTimeout(async () => fetchScores(fetchHighScores, setTeams), 4000)
        return () => clearInterval(t)
      }

      memoizedGetTeamsData(props.location);
      const t = setInterval(() => { memoizedGetTeamsData(props.location) }, 1000);
      return () => {
        clearInterval(t);
      };
    }, [ELocations.GENERAL, fetchHighScores, setTeams, memoizedGetTeamsData, props]);
  }