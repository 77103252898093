import { useEffect } from "react";
import pingApiServer from "api/pingApiServer";
import { useCallback } from "react";

/**
 * @name useEffectHookOnServerError
 * @param {*} pingServer 
 * @param {*} setGotError 
 * @exports useEffectHookOnServerError hook in the ServerError.jsx component
 */

export function useEffectHookOnServerError(pingServer, setGotError) {
    const memoizedPingServer = useCallback(
      () => pingServer(pingApiServer, setGotError),
      [pingServer, setGotError]
    );
  
    useEffect(() => {
      const t = setInterval(memoizedPingServer, 1000);
  
      return () => {
        clearInterval(t);
      };
    }, [memoizedPingServer]);
  }
