/**
 * @name CarouselSettings
 * @exports settings data for CarouselSettings.jsx component
 */
export const CarouselSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "15px",
  slidesToShow: 1,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2000,
};
