import config from "../initConfig";

/**
 * @name fetchConfiguration
 * @returns {(location, viewMode) => object} Configuration file
 */
function fetchConfiguration(location, viewMode) {
  const dummyConfig = {
    viewMode: 1,
    enabled: 1,
    layout: "diamond",
  };
  const { urlConstructor, locationConstructor } = config;

  const URL = urlConstructor();
  const LOCATION = location ? location : locationConstructor();

  return LOCATION === "general"
    ? dummyConfig
    : fetch(`${URL}/getStatus.php?location=${LOCATION}`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => {
          console.log(error);
          return [];
        });
}
export default fetchConfiguration;
