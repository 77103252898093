import config from "initConfig";
const { locationConstructor } = config;
let location = locationConstructor();

export function getRoomLogo(roomName, loc) {
  let img;
  try {
    img = require(`assets/images/${loc ? loc : location.toLowerCase()}/${roomName}.svg`);
  } catch (error) {
    return img;
  }
  return img;
}

export function getDefaultRoomLogo(roomName) {
  let img;
  try {
    img = require(`assets/images/${roomName}.svg`);
  } catch (error) {
    console.warn(`Cannot Find asset : assets/images/${roomName}.svg`);
  }
  return img;
}

export function getDefaultLogo(roomName) {
  let img;
  try {
    img = require(`assets/images/default.svg`);
  } catch (error) {
    console.warn(`Cannot Find asset : assets/images/${roomName}.svg`);
  }
  return img;
}
