import config from "./app-config.json"

const {
    build: { mode, type },
    location,
    locations,
    urls
} = config;
let URL;
let LOCATION;

const urlConstructor = () => {
    if (mode === "WEB") {
        return URL = urls.leaderboard || process.env.REACT_APP_URL_LEADERBOARD_SYSTEM;
    }
    if (mode === "APP") {
        //  If not set raise warning
        if (!window.ENV) {
            console.warn('Warning URL Vars not detected')
            URL = urls.leaderboard  || process.env.REACT_APP_URL_LEADERBOARD_SYSTEM;
            console.log({URL})
        };
        URL = window.ENV.URL_LEADERBOARD_SYSTEM|| urls.leaderboard;
        return URL
    }

}

const locationConstructor = () => {
    if (mode === "WEB") {
        return LOCATION = location;
    }
    if (mode === "APP") {
        if (!window.ENV) {
            LOCATION = location || process.env.REACT_APP_LOCATION ;
            console.warn('Warning Location Environment Vars not detected')
            console.log({LOCATION})
            return LOCATION
        };
        LOCATION =  window.ENV.LOCATION || location;
        return LOCATION
    }
}
const isWeb = () => mode === "WEB"
const isGeneralLeaderboard = () => type === "main"

const getOnlineConfig = async () => {
    const res = await fetch(`${urls.leaderboard}/config.php?location=${locationConstructor()}`, { method: "GET" })
    return (await res.json())
}
const constructors = {
    urlConstructor,
    locationConstructor,
    isWeb,
    isGeneralLeaderboard,
    getOnlineConfig
}
export default constructors