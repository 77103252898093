import config from "../initConfig";

function pingApiServer() {
  const { urlConstructor } = config;
  const URL = urlConstructor();

  return fetch(`${URL}/ping.php`)
    .then(() => true)
    .catch((error) => {
      console.log(error);
      return false;
    });
}
export default pingApiServer;
